export const ARCHIVE_BLUE = '#3A549C';
export const BLACK = '#000000';
export const BLUEJAY = '#0F556C';
export const BLUEJAY_LHT = '#C3DEE7';
export const CHALK = '#ECEAE7';
export const CLOUD_DARK = '#757575';
export const CLOUD_LIGHT = '#BABABA';
export const CONSENT_ACTION = '#F6A21D';
export const CONSENT_BACKGROUND = '#323232';
export const CONSENT_CONTENT = '#BEBEBE';
export const CONSENT_FOCUS = '#68A1F8';
export const DARK_SALTIRE = '#23104C';
export const DIM_GREY = '#696969';
export const EBON = '#222222';
export const ERROR_CORE = '#E51854';
export const GHOST = '#FDFDFD';
export const GREY_10 = '#141414';
export const GREY_11 = '#BABABA';
export const GREY_1 = '#FEFEFE';
export const GREY_2 = '#F6F6F6';
export const GREY_3 = '#E6E8EA';
export const GREY_4 = '#B0B2B4';
export const GREY_5 = '#8A8C8E';
export const GREY_6 = '#545658';
export const GREY_7 = '#3A3C3E';
export const GREY_8 = '#202224';
export const KINGFISHER = '#11708C';
export const LE_TEAL = '#09838B';
export const LIVE_LIGHT = '#00CCC7';
export const LIVE_MEDIUM = '#008282';
export const LIVE_DARK = '#006666';
export const LIVE_CORE = '#009E9E';
export const LUNAR = '#F2F2F2';
export const LUNAR_LIGHT = '#F8F8F8';
export const METAL = '#6E6E73';
export const MIDNIGHT_BLACK = '#121212';
export const NEWSROUND_PURPLE = '#6C22D6';
export const NEWSROUND_PURPLE_30 = '#9159A8';
export const OAT_LHT = '#F5F3F1';
export const ORBIT_GREY = '#4C4C4C';
export const PEBBLE = '#AEAEB5';
export const PHILIPPINE_GREY = '#8A8C8E';
export const POSTBOX = '#B80000';
export const POSTBOX_30 = '#EAB3B3';
export const RHINO = '#5A5A5A';
export const SERVICE_NEUTRAL_CORE = '#0071F1';
export const SERVICE_NEUTRAL_DARK = '#0051AD';
export const SHADOW = '#3F3F42';
export const SPORT_MIST = '#F7F7F5';
export const SPORT_SILVER = '#DBDBDB';
export const SPORT_YELLOW = '#FFD230';
export const SPORT_YELLOW_30 = '#BB9A31';
export const STONE = '#D5D0CD';
export const STORM = '#404040';
export const SUCCESS_CORE = '#148A00';
export const WEATHER_BLUE = '#067EB3';
export const WHITE = '#FFFFFF';
